// centering
@mixin vertical-center() {
  display: flex;
  justify-content: center;
}

@mixin horizontal-center() {
  display: flex;
  align-items: center;
}

@mixin center() {
  @include vertical-center();
  @include horizontal-center();
}

// media queries
@mixin max-screen {
  @media only screen and (max-width: $max-width) {
    @content;
  }
}

@mixin xlg-screen {
  @media only screen and (max-width: $max-width) {
    @content;
  }
}

@mixin lg-screen {
  @media only screen and (max-width: $lg-width) {
    @content;
  }
}

@mixin md-screen {
  @media only screen and (max-width: $mid-width) {
    @content;
  }
}

@mixin sm-screen {
  @media only screen and (max-width: $small-width) {
    @content;
  }
}
