.Stories {
  background: $darkmarine;
  color: white;

  .container {
    max-width: $max-width;
    margin: auto;
    padding: 50px 0;

    @include xlg-screen {
      padding: 30px 0;
    }

    .slider {
      height: 500px;
    }

    .nextButton,
    .previousButton {
      svg polygon {
        fill: white;
      }
    }

    .story-card {
      display: flex;
      flex-direction: row;

      @include xlg-screen {
        flex-direction: column;
      }

      .img {
        @include center;

        img {
          width: 375px;
          height: 375px;
          border-radius: 50%;
          margin-left: 100px;
          margin-right: 50px;
          margin-top: 5px;
          border: 8px solid $red;

          @include max-screen {
            width: 320px;
            height: 320px;
            margin-right: 30px;
          }

          @include xlg-screen {
            width: 250px;
            height: 250px;
            margin: 10px;
          }
        }
      }

      .bio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 80px;

        @include xlg-screen {
          margin: 10px;
        }

        .quote {
          font-size: 30px;
          text-align: center;

          @include max-screen {
            font-size: 25px;
          }

          @include xlg-screen {
            font-size: 16px;
            max-width: 500px;
          }

          svg {
            fill: $red;
          }
        }

        button {
          width: 200px;
          margin: 15px;

          @include xlg-screen {
            font-size: 15px;
          }

          svg {
            margin-right: 0;
            margin-left: 8px;
          }
        }

        .name {
          font-size: 25px;
          text-align: center;

          @include xlg-screen {
            font-size: 15px;
          }
        }

        .description {
          font-size: 20px;
          text-align: center;

          @include xlg-screen {
            font-size: 15px;
          }
        }
      }
    }
  }
}
