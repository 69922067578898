.Home {
  min-height: calc(100vh - #{$navbar-height}) !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
    url("../../assets/img/Congress.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include center;
  display: flex;
  flex-direction: column;

  .content {
    font-size: 28px;
    text-align: center;
    max-width: 900px;

    @include lg-screen {
      font-size: 22px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    margin: 20px;
  }
}
