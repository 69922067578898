.About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    max-width: $max-width;
    margin-bottom: 20px;
  }

  .videos {
    width: 100%;
    max-width: $max-width;
    margin-bottom: 40px;

    .video-grid {
      max-width: $max-width;
      width: 100%;
      display: -ms-grid;
      display: grid;
      justify-items: center;
      align-items: center;
      grid-template-columns: 50% 50%;
      column-gap: 10px;
      row-gap: 10px;

      @include lg-screen {
        grid-template-columns: auto;
      }

      .vid-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 300px;

        @include max-screen {
          width: 100%;
          height: 225px;
        }

        @include lg-screen {
          width: 100%;
          height: calc(90vw / 2);
          min-height: 150px;
        }
      }
    }
  }

  .bill {
    width: 100%;
    max-width: $max-width;

    .bill-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      @include xlg-screen {
        flex-direction: column;
      }

      .BillViewer {
        @include center;
        flex-direction: column;

        &.true {
          z-index: 3;
        }

        .bill-img {
          width: 350px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          transition: transform 350ms;

          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }

          @include xlg-screen {
            margin-top: 20px;
            width: 500px;
            max-width: 100%;
          }
        }

        button {
          width: 100%;
        }
      }

      .details {
        padding-bottom: 30px;
      }
    }
  }
}
