.Navbar {
  background: $marine;
  width: 100vw;
  min-width: $min-width;
  height: $navbar-height;
  @include center;
  position: fixed;
  z-index: 2;

  // normal menu
  .content {
    width: 100%;
    height: $navbar-height;
    max-width: $max-width;
    padding: 0 50px;
    display: flex;
    justify-content: space-between;

    @include lg-screen {
      padding: 0 20px;
    }

    .logo {
      margin-top: 10px;
      height: 120px;
      border-radius: 50%;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      &:hover {
        cursor: pointer;
      }
    }

    .links {
      height: 100%;
      display: flex;
      align-items: center;
      color: white;
      font-size: 22px;
      text-transform: uppercase;

      .link {
        margin: 0 10px;
        padding: 10px 15px;

        @include lg-screen {
          margin: 0 5px;
        }

        &:hover {
          cursor: pointer;
          background: $red;
          transition: 200ms;
        }
      }
    }
  }

  // dropdown menu
  .content {
    .dropdown {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: fit-content;

      .icon {
        height: $navbar-height;
        display: flex;
        align-items: center;
      }

      .icon svg {
        height: 50px;
        width: 50px;
        color: white;
        margin-right: 15px;
        z-index: 1;

        &:hover {
          cursor: pointer;
        }
      }

      $dropdown-width: 200px;

      .marine {
        height: $navbar-height;
        width: $dropdown-width;
        background: $marine;
        position: absolute;
        z-index: 1;
      }

      .links {
        overflow: hidden;
        width: $dropdown-width;
        padding: 20px 0;
        background: $darkmarine;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        transition: 700ms;
        position: absolute;
        height: fit-content;
        top: $navbar-height;
      }

      .links.false {
        top: -150px;
      }
    }
  }
}
