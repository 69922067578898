@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.open-sans {
  font-family: "Open Sans", sans-serif;
}

h1 {
  color: $darkred;
  text-transform: uppercase;
  text-shadow: 0px 2px rgb(153, 153, 153);
}

p {
  font-size: 22px;

  @include lg-screen {
    font-size: 18px;
  }
}

p.long-text {
  font-size: 22px;

  @include lg-screen {
    font-size: 18px;
  }
}

mark.white {
  background-color: white;
}

.bold {
  font-weight: bolder;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 72%,
    $lightred 50%
  );
}

.italic {
  font-style: italic;
  text-decoration: underline;
}

.number {
  color: $marine;
  font-size: 38px;
  font-weight: bold;
  position: relative;
  top: 5px;
}
