.Footer {
  background: $gray;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  font-size: 20px;
  text-align: center;

  .logo {
    height: 160px;
    margin-bottom: 25px;

    &:hover {
      cursor: pointer;
    }
  }

  a {
    color: white;
  }

  .mail a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .media {
    width: 300px;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    border-top: 1px solid gray;

    svg {
      height: 45px;
      width: 45px;
      padding: 0 15px;
    }
  }
}
