// screen sizes
$max-width: 1200px;
$xlg-width: 1000px;
$lg-width: 850px;
$mid-width: 700px;
$small-width: 400px;
$min-width: 350px;

// colors
$lightmarine: #496ab3;
$marine: #183981;
$darkmarine: #152f68;
$lightred: #d37878;
$red: #d64b4b;
$darkred: #c94343;
$gray: #2b303a;

// sizes
$navbar-height: 100px; // when changing this, also change value in Scroll.js
