button {
  padding: 15px 20px;
  margin: 10px;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  background: $red;
  color: white;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  @include sm-screen {
    padding: 10px 15px;
  }

  svg {
    margin-right: 8px;
  }

  &:hover {
    transition: 300ms;
    background: $darkred;
    cursor: pointer;
  }
}
