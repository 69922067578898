.Action {
  max-width: $max-width;
  margin: auto;
  padding-bottom: 60px;

  .content {
    display: grid;
    grid-template-columns: 45% auto;
    column-gap: 10px;

    @include lg-screen {
      grid-template-columns: auto;
      row-gap: 15px;
    }

    .tool {
      border: 2px $lightmarine solid;
      padding: 15px;

      .location {
        margin-bottom: 10px;
        margin-top: -10px;
        span {
          font-weight: bold;
        }
      }

      form {
        display: grid;
        grid-template-columns: 70% auto;
        column-gap: 5px;
        margin-bottom: 20px;

        @include md-screen {
          grid-template-columns: auto;
          input[type="submit"] {
            margin-top: 10px;
            padding: 10px 0;
          }
        }

        input[type="text"] {
          height: 40px;
          border: 2px solid $marine;
          border-radius: 4px;
          padding: 0 10px;
          font-size: 18px;
          color: $marine;
          text-align: center;

          &::placeholder {
            text-align: center;
            font-style: italic;
          }
        }

        input[type="submit"] {
          -webkit-appearance: none;
          font-family: "Open Sans", sans-serif;
          font-size: 16px;
          border-radius: 4px;
          background: $lightmarine;
          color: white;
          border: none;

          &:hover {
            transition: 300ms;
            background: $marine;
            cursor: pointer;
          }
        }
      }
    }

    .form-results {
      margin-top: -5px;
      padding-bottom: 10px;
      text-transform: capitalize;
      font-size: 16px;
      width: 100%;

      th {
        text-align: left;
        padding: 0;
        border-bottom: 1px black solid;
      }

      td {
        text-align: left;
        vertical-align: top;
        padding: 0;
      }

      tr:nth-child(even) {
        background-color: #f2f2f2;
      }

      .party {
        font-size: 14px;
      }
    }

    .error {
      color: red;
      margin-top: -5px;
      padding-bottom: 10px;
    }

    .alternative {
      border-top: 1px solid black;
      padding-top: 10px;
      font-size: 14px;
    }

    .template {
      padding: 15px;
      background: $lightmarine;
      color: white;

      .italic {
        color: $lightred;
      }
    }
  }

  .letter {
    margin-bottom: 0;
    padding-top: 10px;
    border-top: 1px black solid;

    .bold {
      text-decoration: none;
      color: black;
    }
  }
}
